import React, { useEffect } from 'react';

import disableScroll from 'disable-scroll';
import { setFalse } from '../../../state/slices';
import { useDispatch } from 'react-redux';

const Modal = (props) => {
  useEffect(() => {
    disableScroll.on();
  }, []);
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setFalse());
    disableScroll.off();
  };

  const styles = {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    background: `var(--gray90)`,
    opacity: 0.95,
    zIndex: 100,
  };
  return <div style={styles} onClick={() => handleClick()} onKeyPress />;
};

export default Modal;
