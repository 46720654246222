import * as styles from './layout.module.css';

import Footer from '../components/Footer/Footer';
import Head from '../components/Head/Head';
import Navbar from './Navbar/Navbar';
import PopupForm from '../components/Forms/PopupForm';
import React from 'react';
import Staging from '../components/Staging/Staging';
import { gateState } from '../state/slices';
import { showPopup } from '../state/slices';
import { useSelector } from 'react-redux';

const Layout = (props) => {
  const showPopupForm = useSelector(showPopup);

  const ContentClasses = [styles.Content];
  if (props.index) {
    ContentClasses.push(styles.Index);
  }
  if (props.noPadding) {
    ContentClasses.push(styles.NoPad);
  }
  const seoData = {
    url: `${props.location.href}`,
    ...props.seoData,
  };

  const authorized = useSelector(gateState);
  return (
    <>
      <Head seoData={seoData} data={props.data} />
      <>
        <Navbar />
        <div className={styles.Layout}>
          <main>
            <div className={ContentClasses.join(' ')}>{props.children}</div>
          </main>
          {showPopupForm ? <PopupForm /> : null}

          <Footer />
          {/* {process.env.GATSBY_ENVIRONMENT !== 'production' && !authorized ? (
            <Staging />
          ) : null} */}
          {process.env.GATSBY_ENVIRONMENT !== 'production' ? (
            <div
              style={{
                zIndex: 100,
                position: 'fixed',
                bottom: '0',
                width: '100vw',
                height: '42px',
                backgroundColor: 'orange',
              }}>
              <h4 style={{ textAlign: 'center' }}>STAGING SITE TEST</h4>
            </div>
          ) : null}
        </div>
      </>
    </>
  );
};

export default Layout;
