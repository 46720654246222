export const getUrl = (arr, id) => {
  const slug = arr.find((el) => {
    return el.context.id === id;
  });
  if (slug !== undefined) {
    return `${slug.path}`;
  } else {
    return;
  }
};

export const getTags = (target) => {
  const tagArr = [];
  target.forEach((el, i) => {
    tagArr.push(el.Tags);
  });
  
  
  const finishedArr = tagArr
    .join(',')
    .split(',')
    .map((el, i) => {
      return el.trim();
    });

  const trimmedArr = finishedArr.filter((el) => {
    return el !== '';
  });
  return trimmedArr;
};

export const getBlogPostTags = (target) => {
  
  const tagArr = [];
  if (target.Tags) {
    target.Tags.split(',').map((el, i) => {
      return tagArr.push(el.trim());
    });
  }
  
  const trimmedArr = tagArr.filter((el) => {
    return el !== '';
  });
    
  return trimmedArr;
};
