import './MenuIcon.module.css';

import React from 'react';

const Hamburger = (props) => {
  return (
    <div className='MenuIcon' id='nav-icon4' onClick={props.click}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default Hamburger;
