import * as styles from './Form.module.css';

import React, { useState } from 'react';

import publicIp from 'public-ip';
import validator from 'validator';

const NewsletterForm = (props) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [formSent, setFormSent] = useState(false);

  const sendData = async (url, options) => {
    const response = await fetch(url, options);
    const data = await response.json();

    if (!response.ok) {
      setMessage('Please enter a different email');
      throw new Error(`HTTP Error! status: ${response.status}`);
    }
    return data;
  };
  const submitForm = async (e) => {
    e.preventDefault();

    if (!validator.isEmail(email) || email === '') {
      setMessage('** Please enter a valid email address');
      return;
    }

    const location = window && window.location.href;
    const pageName = document && document.title;
    const cookie =
      document &&
      document.cookie
        .split('; ')
        .find((row) => row.startsWith('hubspotutk='))
        .split('=')[1];

    const ip = await publicIp.v4();
    const data = {
      fields: [
        {
          name: 'email',
          value: email.toLocaleLowerCase(),
        },
      ],
      context: {
        hutk: `${cookie}`,
        pageUri: location,
        pageName: pageName,
        ipAddress: `${ip}`,
      },
    };

    const url =
      'https://api.hsforms.com/submissions/v3/integration/submit/2687165/36b44ab2-97bb-4989-8eb0-4fc5d5556b11';
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    };

    const response = await sendData(url, options);
    setMessage(response.inlineMessage);
    setFormSent(true);
  };

  return (
    <div className={styles.Newsletter}>
      {!formSent ? (
        <>
          <form className={styles.Form} onSubmit={(e) => submitForm(e)}>
            <label className={styles.Label} htmlFor='email'>
              Sign up for our email list!
            </label>
            <div className={styles.NewsletterFormRow}>
              <input
                id='email'
                name='email'
                className={styles.Input}
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input className={styles.Button} type='submit' value='Sign up' />
            </div>
          </form>
          <div className={styles.Message}>{message}</div>
        </>
      ) : (
        <div className={styles.FormMessage}>{message.replace(/<[^>]+>/g, '')}</div>
      )}
    </div>
  );
};

export default NewsletterForm;
