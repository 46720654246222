import { graphql, useStaticQuery } from 'gatsby';

import Favicon from '../../images/V3_Favicon_Lt-Mode.ico';
import { Helmet } from 'react-helmet';
import React from 'react';

const Seo = (items) => {
  const seoData=items.seoData;


  const { strapiGeneralSeo, strapiIntegrations } = useStaticQuery(graphql`
    query seoQuery {
      strapiGeneralSeo {
        SiteSEO {
          Keywords
          MetaDescription
          MetaTitle
          Slug
          PreviewImage {
            url
            width
            height
          }
        }
      }
      strapiIntegrations {
        GoogleAnalytics
        Hubspot
        LeadLander
      }
    }
  `);
  const generalSEO = strapiGeneralSeo.SiteSEO;
    
  const getPreviewImage = (seoData) => {
    if ('PreviewImage' in seoData && seoData.PreviewImage !== null) {
      if ('formats' in seoData.PreviewImage) {
        return { ...seoData.PreviewImage.formats.small };
      } else {
        return { ...seoData.PreviewImage };
      }
    } else return { ...generalSEO.PreviewImage };
  };

  const seo = seoData
    ? {
        metaTitle:
          seoData.MetaTitle !== undefined
            ? seoData.MetaTitle
            : generalSEO.MetaTitle,
        metaDescription:
          seoData.MetaDescription !== undefined
            ? seoData.MetaDescription
            : generalSEO.MetaDescription,
        keywords:
          seoData.Keywords !== undefined
            ? seoData.Keywords
            : generalSEO.Keywords,
        previewImage: getPreviewImage(seoData),
        author:
          seoData.Author !== undefined ? seoData.Author : 'V3 Distribution',
        date: seoData.Date !== undefined ? seoData.Date : null,
        url: seoData.url,
      }
    : {
        metaTitle: generalSEO.MetaTitle,
        metaDescription: generalSEO.MetaDescription,
        keywords: generalSEO.Keywords,
        previewImage: { ...generalSEO.PreviewImage },
        author: 'V3 Distribution',
      };

  const googleId = strapiIntegrations.GoogleAnalytics;
  const hubspotId = strapiIntegrations.Hubspot;
  const leadLanderId = strapiIntegrations.LeadLander;



  

  return (
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <link rel='icon' href={Favicon} />
      <title>{seo.metaTitle}</title>
      <link rel='canonical' href={seo.url} />
      <meta name="url" property='og:url' content={seo.url} />
      <meta name="keywords" property='og:keywords' content={seo.keywords} />
      <meta name="title" property='og:title' content={seo.metaTitle} />
      <meta name="description" property='og:description' content={seo.metaDescription} />
      <meta name="site_name" property='og:site_name:' content='V3 Distribution' />
      <meta name='description' content={seo.metaDescription} />
      {seo.date && <meta name='date' content={seo.date} />}
      <meta property='og:type' content={seo.keywords && seo.keywords.includes("V3 Distribution, shop, products,")?"product":((seo.author && seo.date) ? 'article' : 'website')} />
      {(seo.author && seo.date) && <meta name='author' content={seo.author} />}
      {(seo.author && seo.date) && <meta property='og:article:author' content={seo.author} />}
      {(seo.author && seo.date) && (
        <meta property='og:article:publisher' content={`V3 Distribution`} />
      )}
      {(seo.author && seo.date) && (
        <meta property='og:article:published_time' content={seo.date} />
      )}
      <meta
        property='og:image:width'
        content={
          seo.previewImage
            ? seo.previewImage.width
            : generalSEO.previewImage.width
        }
      />

      <meta
        property='og:image:height'
        content={
          seo.previewImage
            ? seo.previewImage.height
            : generalSEO.previewImage.height
        }
      />
      
      <meta
        property='og:image'
        content={
          (i=>i!=='undefined' && i.length>10)(String((()=>{try{return items.data.strapiTeamMembers.SEO.PreviewImage.url }catch{}})()))
            ? items.data.strapiTeamMembers.SEO.PreviewImage.url
            : seo.previewImage ? seo.previewImage.url : generalSEO.previewImage.url
        }
      />
      <meta property='og:image:secure_url' content={seo.previewImage.url} />

      {process.env.GATSBY_ENVIRONMENT !== 'production' ? null : (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${googleId}`}
        />
      )}

      {process.env.GATSBY_ENVIRONMENT !== 'production' ? null : (
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${googleId}');
        `}
        </script>
      )}
      <script
        charset='utf-8'
        type='text/javascript'
        async
        defer
        src='//js.hsforms.net/forms/shell.js'></script>
      <script
        type='text/javascript'
        id='hs-script-loader'
        async
        defer
        src={`//js.hs-scripts.com/${hubspotId}.js`}
      />
      {/* <script>
        {leadLanderId &&
          `var llcookieless = false;
          var formalyze = [];
          formalyze.auto = true;
          formalyze.callback = function(options) {};
          (function() {
            var a = document.createElement('script');
            a.src = 'https://lltrck.com/scripts/lt-v3.js?llid=${leadLanderId}';
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(a, s);
            })();`}
      </script> */}
    </Helmet>
  );
};

export default Seo;
