import * as styles from './Footer.module.css';

import { Link, graphql, useStaticQuery } from 'gatsby';

import FooterEmail from '../../images/email-gray.svg';
import FooterLinkedIn from '../../images/linkedin-gray.svg';
import FooterMaps from '../../images/location-gray.svg';
import FooterPhone from '../../images/phone-gray.svg';
import FooterYoutube from '../../images/youtube-gray.svg';
import NewsletterForm from '../Forms/NewsletterForm';
import React from 'react';
import { getUrl } from '../../util/index';

const Footer = (props) => {
  const data = useStaticQuery(graphql`
    query sitemapQuery {
      strapiPageHierarchy {
        Pages {
          Page {
            Title
            PageData {
              id
              SEO {
                Slug
              }
            }
          }
          Children {
            Title
            ChildPageData {
              id
              SEO {
                Slug
              }
            }
          }
        }
      }
      strapiFooterText {
        PhoneNo
        Email
        Address
        Line2
      }
      strapiIntegrations {
        Linkedin
        Youtube
        GoogleMaps
      }
      allSitePage {
        nodes {
          path
          context {
            id
          }
        }
      }
    }
  `);

  const footerData = data.strapiFooterText;
  const anchorPage = data.strapiPageHierarchy.Pages;
  const socialLinks = data.strapiIntegrations;
  const urlList = data.allSitePage.nodes;

  const sitemap = anchorPage.map((page, i) => {
    return (
      <ul className={styles.NavList} key={i}>
        <li>
          <Link
            to={`${getUrl(urlList, page.Page.PageData.id)}`}
            key={i}
            className={styles.AnchorPage}>
            {page.Page.Title}
          </Link>
        </li>
        {page.Page.Title === 'About V3' ? (
          <li key={'team'}>
            <Link to={'/about/team'}>Team</Link>
          </li>
        ) : null}{' '}
        {page.Page.Title === 'About V3' ? (
          <li key={'careers'}>
            <Link to={'/about/careers'}>Careers</Link>
          </li>
        ) : null}
        {page.Page.Title === 'About V3' ? (
          <li key={'blog'}>
            <Link to={'/about/blog'}>Blog</Link>
          </li>
        ) : null}
        {page.Children.map((child, i) => {
          return (
            <li key={i}>
              <Link to={`${getUrl(urlList, child.ChildPageData.id)}`}>
                {child.Title}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  });

  return (
    <div className={styles.Footer}>
      <div className={styles.Newsletter}>
        <NewsletterForm />
      </div>
      <div className={styles.Sitemap}>{sitemap}</div>
      <div className={styles.Social}>
        <a href={`tel:${footerData.PhoneNo}`}>
          <FooterPhone />
        </a>
        <a href='mailto:info@v3distribution.com'>
          <FooterEmail />
        </a>
        <a
          href={socialLinks.GoogleMaps}
          target='_blank'
          rel='noopener noreferrer'>
          <FooterMaps />
        </a>
        <a
          href={socialLinks.Linkedin}
          target='_blank'
          rel='noopener noreferrer'>
          <FooterLinkedIn />
        </a>
        <a href={socialLinks.Youtube} target='_blank' rel='noopener noreferrer'>
          <FooterYoutube />
        </a>
      </div>
      <address>
        <a
          href={socialLinks.GoogleMaps}
          target='_blank'
          rel='noopener noreferrer'>
          {' '}
          {footerData.Address}
        </a>{' '}
        <span className={styles.Desktop}>
          | <a href={`tel:${footerData.PhoneNo}`}>{footerData.PhoneNo}</a> |{' '}
          <a href={`mailto: ${footerData.Email}`}>{footerData.Email}</a>
        </span>
      </address>
      <p>
        <a href='/terms-and-conditions'>Terms & Conditions</a> | COPYRIGHT ©
        2021 V3 <span className={styles.Desktop}>All Right Reserved</span>
      </p>
    </div>
  );
};

export default Footer;
