import React, { useEffect, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql, useStaticQuery } from 'gatsby';
import * as styles from './Navbar.module.css';

// Import the Navigation component
import Navigation from '../../components/Navigation/Navigation';

// Import the HeaderImages component
import HeaderImages from '../HeaderImages/HeaderImages';

const Navbar = (props) => {
  // Add state to control the opacity of the HeaderImages component
  const [headerImagesOpacity, setHeaderImagesOpacity] = useState(1);

  // Add useEffect to handle the scroll event
  useEffect(() => {
    const handleScroll = () => {
      const navbarElement = document.querySelector(`.${styles.Navbar}`);
      if (navbarElement) {
        const scrollTop = window.pageYOffset;
        let dynamicnumber;
  
        if (window.innerWidth > 768) {
          dynamicnumber = 90;
        } else if (window.innerWidth > 526) {
          dynamicnumber = 90;
        } else if (window.innerWidth > 340) {
          dynamicnumber = 80;
        } else {
          dynamicnumber = 60;
        }
  
        const newTop = Math.max(dynamicnumber - scrollTop, 0);
        navbarElement.style.top = `${newTop}px`;
      }
    };

    handleScroll();
  
    window.addEventListener('scroll', handleScroll);
    //handle scroll on resize
    window.addEventListener('resize', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, []);
  

  const logoQuery = useStaticQuery(graphql`
    query logoQuery {
      strapiSiteImages {
        Logo {
          url
        }
      }
    }
  `);
  const logo = logoQuery.strapiSiteImages.Logo;

  return (
    <>
      {/* Add the HeaderImages component and pass the opacity as a style prop */}
      <HeaderImages style={{ opacity: headerImagesOpacity }} />
      <div className={styles.Navbar}>
        <div className={styles.NavContent}>
          <div className={styles.Logo}>
            <Link to='/' aria-label='logo'>
              <img src={logo.url} alt='V3 Logo' loading='eager' />
            </Link>
          </div>
          <div className={styles.Navigation}>
            <Navigation />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
