// extracted by mini-css-extract-plugin
export var Popup = "Popup-module--Popup--3tln6";
export var PopupForm = "Popup-module--PopupForm--5g3ld";
export var FormWrapper = "Popup-module--FormWrapper--hBQrm";
export var TextArea = "Popup-module--TextArea--2E011";
export var Input = "Popup-module--Input--jyfK4";
export var Button = "Popup-module--Button--2kjKB";
export var FormRow = "Popup-module--FormRow--g60wZ";
export var ButtonRow = "Popup-module--ButtonRow--1tRDJ";
export var FormBlock = "Popup-module--FormBlock--A5AOf";
export var Inputs = "Popup-module--Inputs--1ZP_o";
export var TextBlock = "Popup-module--TextBlock--RtkC3";
export var TextWrapper = "Popup-module--TextWrapper--1QN2E";
export var Exit = "Popup-module--Exit--213di";
export var Message = "Popup-module--Message--ESxJi";
export var CompleteMessage = "Popup-module--CompleteMessage--1AbZW";