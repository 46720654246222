import React from 'react';
import { Link } from 'gatsby';
import * as styles from './HeaderImages.module.css';

const image1 = 'https://d2t1ydnqrsm3ke.cloudfront.net/headerlogos/V3_Website-Header_Mockup_OEM-Authorizations_BADGES_Cisco_Excess.svg';
const image2 = 'https://d2t1ydnqrsm3ke.cloudfront.net/headerlogos/dellheader.jpg';
const image3 = 'https://d2t1ydnqrsm3ke.cloudfront.net/headerlogos/V3_Website-Header_Mockup_OEM-Authorizations_BADGES_HPE_Authorized-Renew-Distributor (2).svg';
// const image4 = 'https://d2t1ydnqrsm3ke.cloudfront.net/headerlogos/V3_Website-Header_Mockup_OEM-Authorizations_BADGES_HPE_Replacement-Parts-Distributor.svg';
const image4 = 'https://d2t1ydnqrsm3ke.cloudfront.net/headerlogos/Replacement-Parts-Business-Insignia.png';

const HeaderImages = () => {
  return (
    <div className={styles.HeaderImages}>
      <Link to="/var-and-reseller-services/manufacturer-relationships/cisco">
        <img src={image1} alt="Cisco Excess" />
      </Link>
      <Link to="/var-and-reseller-services/manufacturer-relationships/dell-technologies">
        <img src={image2} alt="Dell Certified Refurbished Distributor" />
      </Link>
      <Link to="/var-and-reseller-services/manufacturer-relationships/hpe/renew">
        <img src={image3} alt="HPE Authorized Renew Distributor" />
      </Link>
      <Link to="/var-and-reseller-services/manufacturer-relationships/hpe/spares">
        <img src={image4} alt="HPE Replacement Parts Distributor" />
      </Link>
    </div>
  );
};

export default HeaderImages;
